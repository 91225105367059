import global from './global.json'
import home from './home.json'
import product from './product.json'
import cart from './cart.json'
import categories from './categories.json'
import order_states from './order_states.json'
import footer from './footer.json'
import seo from './seo.json'

export default {
  global,
  home,
  product,
  cart,
  categories,
  order_states,
  footer,
  seo,
}
