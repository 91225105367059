import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import cs from './lang/cs/cs'
import sk from './lang/sk/sk'
import de from './lang/de/de'
import en from './lang/en/en'
import hu from './lang/hu/hu'

export const supportedLanguages = ['cs', 'sk', 'de', 'en', 'hu'] as const
export type TSupportedLanguages = (typeof supportedLanguages)[number]
export const defaultLang = 'en'

export const price = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

export const detailedPrice = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
})

export const defaultNS = 'global'
export const resources = {
  cs,
  sk,
  de,
  en,
  hu,
} as const

export type TranslatedString = { [Property in TSupportedLanguages]: string }

export const changeLanguage = (lang: TSupportedLanguages) => {
  // i18next.changeLanguage(lang)
  window.location.href = `${window.location.origin}/${lang}`
}

i18next.use(LanguageDetector).use(initReactI18next)

if (!i18next.isInitialized) {
  i18next.init({
    ns: [defaultNS],
    fallbackLng: defaultLang,
    supportedLngs: supportedLanguages,
    // lng: userLanguage,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    defaultNS,
    resources,
    debug: false,
    detection: {
      order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
    },
  })
}

export default i18next
